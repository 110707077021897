// Instruments
// I'm an idiot when it comes to score order...

const instruments = [
  { // Woodwinds Placeholder
    value: "Woodwinds",
    solo: false,
    placeholder: true
  },
  { // Piccolo
    value: "Piccolo",
    solo: false,
    prefix: "PC"
  },
  { // Flute
    value: "Flute",
    solo: false,
    prefix: "FL"
  },
  { // Oboe
    value: "Oboe",
    solo: false,
    prefix: "OB"
  },
  { // English Horn
    value: "English Horn",
    solo: false,
    prefix: "EH"
  },
  { // Clarinet
    value: "Clarinet",
    solo: false,
    prefix: "CT"
  },
  { // Alto Clarinet
    value: "Alto Clarinet",
    solo: false,
    prefix: "AC"
  },
  { // Bass Clarinet
    value: "Bass Clarinet",
    solo: false,
    prefix: "BC"
  },
  { // Bassoon
    value: "Bassoon",
    solo: false,
    prefix: "BS"
  },
  { // Contrabassoon
    value: "Contrabassoon",
    solo: false,
    prefix: "CB"
  },
  { // Alto Saxophone
    value: "Alto Saxophone",
    solo: false,
    prefix: "AS"
  },
  { // Tenor Saxophone
    value: "Tenor Saxophone",
    solo: false,
    prefix: "TS"
  },
  { // Baritone Saxophone
    value: "Baritone Saxophone",
    solo: false,
    prefix: "BS"
  },
  ///
  { // Brass Placeholder
    value: "Brass",
    solo: false,
    placeholder: true
  },
  { // French Horn
    value: "French Horn",
    solo: false,
    prefix: "FH"
  },
  { // Trumpet
    value: "Trumpet",
    solo: false,
    prefix: "TP"
  },
  { // Trombone
    value: "Trombone",
    solo: false,
    prefix: "TB"
  },
  { // Baritone
    value: "Baritone",
    solo: false,
    prefix: "BA"
  },
  // {
  //   value: "Bass Trombone",
  //   solo: false,
  //   prefix: "BT"
  // },
  { // Tuba
    value: "Tuba",
    solo: false,
    prefix: "TU"
  },
  ///
  { // Other Placeholder
    value: "Other",
    solo: false,
    placeholder: true
  },
  { // Percussion
    value: "Percussion",
    solo: false,
    prefix: "PR"
  },
  { // Drum Set
    value: "Drum Set",
    solo: false,
    prefix: "DS"
  },
  { // Harp
    value: "Harp",
    solo: false,
    prefix: "HP"
  },
  { // Piano
    value: "Piano",
    solo: true,
    prefix: "PN"
  },
  ///
  { // Strings Placeholder
    value: "Strings",
    solo: false,
    placeholder: true
  },
  { // Violin
    value: "Violin",
    solo: true,
    prefix: "VI",
    completed: false
  },
  { // Viola
    value: "Viola",
    solo: true,
    prefix: "VA"
  },
  { // Cello
    value: "Cello",
    solo: true,
    prefix: "CL"
  },
  { // Double Bass
    value: "Double Bass",
    solo: true,
    prefix: "DB"
  }

];

/* 
const instruments = [
  %{ solo: false, name: 'Alto Flute' },
  %{ solo: false, name: 'Bass Trombone' },
  %{ solo: false, name: 'Bassoon' },
  %{ solo: true, name: 'Cello' },
  %{ solo: false, name: 'Clarinet' },
  %{ solo: false, name: 'Contrabassoon' },
  %{ solo: true, name: 'Double Bass' },
  %{ solo: false, name: 'Flute' },
  %{ solo: false, name: 'French Horn' },
  %{ solo: true, name: 'Harp' },
  %{ solo: false, name: 'Oboe' },
  %{ solo: false, name: 'Percussion' },
  %{ solo: true, name: 'Piano' },
  %{ solo: false, name: 'Piccolo' },
  %{ solo: false, name: 'Trombone' },
  %{ solo: false, name: 'Trumpet' },
  %{ solo: false, name: 'Tuba' },
  %{ solo: true, name: 'Viola' },
  %{ solo: true, name: 'Violin' }
]; */

export default instruments;