const woodwinds = {
  name: "Woodwinds and Brass",
  parts: [
    {
      name: "Part I. Scales",
      description: "Worth a maximum of 60 points. No decimals.",
      sections: [
        {
          name: "MAJOR SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        },
        {
          name: "MINOR SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        },
        {
          name: "CHROMATIC SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        }
      ]
    },
    {
      name: "Part II.  Excerpt(s)",
      description: "Each excerpt is worth 60 points for a maximum total of 120 points. No decimals.",
      sections: [
        {
          name: "EXCERPT #1",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Intonation accuracy",
              scale: "0-12"
            },
            {
              name: "Tone with rich, intense and strong quality",
              scale: "0-12"
            },
            {
              name: "Musicality in their dynamics, phrasing, articulations",
              scale: "0-12"
            }
          ]
        },
        {
          name: "EXCERPT #2",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Intonation accuracy",
              scale: "0-12"
            },
            {
              name: "Tone with rich, intense and strong quality",
              scale: "0-12"
            },
            {
              name: "Musicality in their dynamics, phrasing, articulations",
              scale: "0-12"
            }
          ]
        }
      ]
    }
  ]
};

const strings = {
  name: "Strings",
  parts: [
    {
      name: "Part I. Scales",
      description: "Worth a maximum of 60 points. No decimals.",
      sections: [
        {
          name: "MAJOR SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        },
        {
          name: "MINOR SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        },
        {
          name: "CHROMATIC SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        }
      ]
    },
    {
      name: "Part II.  Excerpt(s)",
      description: "Each excerpt is worth 60 points for a maximum total of 120 points. No decimals.",
      sections: [
        {
          name: "EXCERPT #1",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Intonation accuracy",
              scale: "0-12"
            },
            {
              name: "Tone with rich, intense and strong quality",
              scale: "0-12"
            },
            {
              name: "Musicality in their dynamics, phrasing, articulations",
              scale: "0-12"
            }
          ]
        },
        {
          name: "EXCERPT #2",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Intonation accuracy",
              scale: "0-12"
            },
            {
              name: "Tone with rich, intense and strong quality",
              scale: "0-12"
            },
            {
              name: "Musicality in their dynamics, phrasing, articulations",
              scale: "0-12"
            }
          ]
        }
      ]
    },
    {
      name: "Part III. Solo",
      description: "The solo is worth a maximum of 60 points. Decimals may be used.",
      sections: [
        {
          name: "SOLO",
          scores: [
            {
              scale: "1-20text",
              name: "SOLO"
            },
            {
              name: "Tempo accuracy",
              scale: "0-5.0"
            },
            {
              name: "Rhythmic accuracy",
              scale: "0-5.0"
            },
            {
              name: "Note accuracy",
              scale: "0-5.0"
            },
            {
              name: "Pitch accuracy",
              scale: "0-5.0"
            },
            {
              name: "Tone with rich, intense, and strong quality",
              scale: "0-5.0"
            },
            {
              name: "Dynamics with consistency",
              scale: "0-5.0"
            },
            {
              name: "Bowings and articulations with consistency",
              scale: "0-5.0"
            },
            {
              name: "Interpretation with historical appropriateness",
              scale: "0-5.0"
            }
          ]
        }
      ]
    }
  ]
};

const brass = {
  name: "Woodwinds and Brass",
  parts: [
    {
      name: "Part I. Scales",
      description: "Worth a maximum of 60 points. No decimals.",
      sections: [
        {
          name: "MAJOR SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        },
        {
          name: "MINOR SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        },
        {
          name: "CHROMATIC SCALE",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-5"
            },
            {
              name: "Note accuracy",
              scale: "0-5"
            },
            {
              name: "Intonation accuracy",
              scale: "0-5"
            },
            {
              name: "Tone with rich, intense and strong quality ",
              scale: "0-5"
            }
          ]
        }
      ]
    },
    {
      name: "Part II.  Excerpt(s)",
      description: "Each excerpt is worth 60 points for a maximum total of 120 points. No decimals.",
      sections: [
        {
          name: "EXCERPT #1",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Intonation accuracy",
              scale: "0-12"
            },
            {
              name: "Tone with rich, intense and strong quality",
              scale: "0-12"
            },
            {
              name: "Musicality in their dynamics, phrasing, articulations",
              scale: "0-12"
            }
          ]
        },
        {
          name: "EXCERPT #2",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Intonation accuracy",
              scale: "0-12"
            },
            {
              name: "Tone with rich, intense and strong quality",
              scale: "0-12"
            },
            {
              name: "Musicality in their dynamics, phrasing, articulations",
              scale: "0-12"
            }
          ]
        }
      ]
    }
  ]
}

const percussion = {
  name: "Percussion",
  parts: [
    {
      name: "Snare",
      description: "70 Total Points Possible",
      sections: [
        {
          name: "Etude",
          scores: [
            {
              name: "Rhythmic Integrity",
              scale: "0-20"
            },
            {
              name: "Dynamics",
              scale: "0-10"
            },
            {
              name: "Interpretation",
              scale: "0-10"
            },
            {
              name: "Accuracy of Rudiments ",
              scale: "0-10"
            },
            {
              name: "Articulation",
              scale: "0-10"
            },
            {
              name: "Tempo",
              scale: "0-10"
            }
          ]
        }
      ]
    },
    {
      name: "Mallets",
      description: "120 Total Points Possible",
      sections: [
        {
          name: "Major Scale:  (20  Points  possible)",
          scores: [
            {
              name: "Note Accuracy",
              scale: "0-5i"
            },
            {
              name: "Tone Production",
              scale: "0-5i"
            },
            {
              name: "Rhythm",
              scale: "0-5i"
            },
            {
              name: "Tempo/Fluency",
              scale: "0-5i"
            }
          ]
        },
        {
          name: "Minor Scale:  (20  Points  possible)",
          scores: [
            {
              name: "Note Accuracy",
              scale: "0-5i"
            },
            {
              name: "Tone Production",
              scale: "0-5i"
            },
            {
              name: "Rhythm",
              scale: "0-5i"
            },
            {
              name: "Tempo/Fluency",
              scale: "0-5i"
            }
          ]
        },
        {
          name: "Chromatic Scale:  (20  Points  possible)",
          scores: [
            {
              name: "Note Accuracy",
              scale: "0-5i"
            },
            {
              name: "Tone Production",
              scale: "0-5i"
            },
            {
              name: "Rhythm",
              scale: "0-5i"
            },
            {
              name: "Tempo/Fluency",
              scale: "0-5i"
            }
          ]
        },
        {
          name: "Etude:  (60  points  possible)",
          scores: [
            {
              name: "Note Accuracy",
              scale: "0-10"
            },
            {
              name: "Rhythm",
              scale: "0-10"
            },
            {
              name: "Dynamics",
              scale: "0-10"
            },
            {
              name: "Interpretation",
              scale: "0-10"
            },
            {
              name: "Articulation",
              scale: "0-10"
            },
            {
              name: "Tempo",
              scale: "0-10"
            }
          ]
        }
      ]
    },
    {
      name: "Timpani",
      description: "60 Total Points Possible",
      sections: [
        {
          name: "Etude",
          scores: [
            {
              name: "Note Accuracy",
              scale: "0-10"
            },
            {
              name: "Rhythm",
              scale: "0-10"
            },
            {
              name: "Dynamics",
              scale: "0-10"
            },
            {
              name: "Interpretation",
              scale: "0-10"
            },
            {
              name: "Articulation",
              scale: "0-10"
            },
            {
              name: "Tempo",
              scale: "0-10"
            }
          ]
        }
      ]
    }
  ]
}

const piano = {
  name: "Piano",
  parts: [
    {
      name: "Part I. Solo",
      description: "The solo is worth a maximum of 60 points. No decimals.",
      sections: [
        {
          name: "SOLO",
          scores: [
            {
              scale: "1-20text",
              name: "SOLO"
            },
            {
              name: "General Sound – Touch Control/Dexterity",
              scale: "0-10"
            },
            {
              name: "Technique (notes & pedaling)",
              scale: "0-10"
            },
            {
              name: "Rhythm & Tempo",
              scale: "0-10"
            },
            {
              name: "Musical Interpretation",
              scale: "0-10"
            }
          ]
        }
      ]
    },
    {
      name: "Part I.  Excerpt(s)",
      description: "Each excerpt is worth 60 points for a maximum total of 120 points. No decimals.",
      sections: [
        {
          name: "EXCERPT #1",
          scores: [
            {
              name: "General Sound – Touch Control/Dexterity",
              scale: "0-15"
            },
            {
              name: "Technique (notes & pedaling) ",
              scale: "0-15"
            },
            {
              name: "Rhythm & Tempo",
              scale: "0-15"
            },
            {
              name: "Musical Interpretation",
              scale: "0-15"
            }
          ]
        },
        {
          name: "EXCERPT #2",
          scores: [
            {
              name: "General Sound – Touch Control/Dexterity",
              scale: "0-15"
            },
            {
              name: "Technique (notes & pedaling) ",
              scale: "0-15"
            },
            {
              name: "Rhythm & Tempo",
              scale: "0-15"
            },
            {
              name: "Musical Interpretation",
              scale: "0-15"
            }
          ]
        }
      ]
    },

  ]
}

const harp = {
  name: "Harp",
  parts: [
    {
      name: "Part I.  Excerpt(s)",
      description: "Each excerpt is worth 48 points for a maximum total of 192 points. No decimals.",
      sections: [
        {
          name: "EXCERPT #1",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Technique in their articulation, pedal, balance",
              scale: "0-12"
            },
            {
              name: "Interpretation in their expression, dynamics, phrasing, tempo, style",
              scale: "0-12"
            }
          ]
        },
        {
          name: "EXCERPT #2",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Technique in their articulation, pedal, balance",
              scale: "0-12"
            },
            {
              name: "Interpretation in their expression, dynamics, phrasing, tempo, style",
              scale: "0-12"
            }
          ]
        },
        {
          name: "EXCERPT #3",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Technique in their articulation, pedal, balance",
              scale: "0-12"
            },
            {
              name: "Interpretation in their expression, dynamics, phrasing, tempo, style",
              scale: "0-12"
            }
          ]
        },
        {
          name: "EXCERPT #4",
          scores: [
            {
              name: "Rhythmic and tempo accuracy",
              scale: "0-12"
            },
            {
              name: "Note accuracy",
              scale: "0-12"
            },
            {
              name: "Technique in their articulation, pedal, balance",
              scale: "0-12"
            },
            {
              name: "Interpretation in their expression, dynamics, phrasing, tempo, style",
              scale: "0-12"
            }
          ]
        },
      ]
    }
  ]
}

/*
const noimpl = {
  name: "Rubric Not Created, Contact Patrick",
  parts: []
}
*/

const rubrics = {

  "Piccolo": woodwinds,
  "Flute": woodwinds,
  "Alto Flute": woodwinds,
  "Oboe": woodwinds,
  "English Horn": woodwinds,
  "Alto Clarinet": woodwinds,
  "Clarinet": woodwinds,
  "Bass Clarinet": woodwinds,
  "Bassoon": woodwinds,
  "Contrabassoon": woodwinds,

  "Alto Saxophone": woodwinds,
  "Tenor Saxophone": woodwinds,
  "Baritone Saxophone": woodwinds,

  "French Horn": brass,
  "Trumpet": brass,
  "Trombone": brass,
  "Bass Trombone": brass,
  "Baritone": brass,
  "Tuba": brass,

  "Violin": strings,
  "Viola": strings,
  "Cello": strings,
  "Double Bass": strings,

  "Percussion": percussion,
  "Drum Set": percussion,
  "Harp": harp,
  "Piano": piano,

};
export default rubrics;