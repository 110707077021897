import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import AdminHeader from './adminheader';
import { Link } from "react-router-dom";
import ls from "local-storage";
import jwtDecode from "jwt-decode";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  footer: {
    marginTop: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(6)}px 0`,
  },
});

class AdminDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dCount: 0,
      dECount: 0,
      dNCount: 0,
      sCount: 0,
      sECount: 0,
      sICount: 0,
      schCount: 0,
      user: jwtDecode(ls('admin-jwt'))
    };
  }

  componentDidMount() {
    fetch("https://admin-api.coloradoaso.org/dashboard", {
      method: 'get',
      headers: {
        'authorization': ls('admin-jwt')
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error.message
          });
        }
      )
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AdminHeader />
        <main className={classes.layout}>
          {/* Hero unit */}
          <div className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Hi, {this.state.user.name}!
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" component="p">
              Use the menu items in the header bar to look at directors, students, auditions, and email lists!
            </Typography>
          </div>
          {/* End hero unit */}
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title="Directors"
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {this.state.dCount}
                    </Typography>
                  </div>
                  <Typography variant="subtitle1" align="center">
                    {this.state.dECount} Confirmed Emails
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    {this.state.dNCount} Confirmed NAfMEs
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button component={Link} to="/caso-admin/directors" fullWidth variant="outlined" color="primary">
                    View Directors
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title="Students"
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {this.state.sCount}
                    </Typography>
                  </div>
                  <Typography variant="subtitle1" align="center">
                    {this.state.sECount} Confirmed Emails
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    {this.state.sICount} Instrument Auditions
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button component={Link} to="/caso-admin/students" fullWidth variant="outlined" color="primary">
                    View Students
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title="Schools"
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {this.state.schCount}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button component={Link} to="/caso-admin/schools" fullWidth variant="outlined" color="primary">
                    View Schools
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }

}

AdminDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminDashboard);